import EventModel from "../../../lib/EventModel";

export default {
  props: ["event"],
  data() {
    return {
      registrationsAndCheckinsOverTimeChartData: null,
      attendeesTotal: this.event.attendees.total,
      attendeesCheckin: this.event.attendees.checkin,
      
    };
  },
  computed: {
    checkinPercentage() {

      // console.log('Total',this.attendeesTotal)

      let totalUsers =
        this.event && this.event.attendees && this.event.attendees.total
          ? this.event.attendees.total
          : 0;
      let checkinUsers =
        this.event && this.event.attendees && this.event.attendees.checkin
          ? this.event.attendees.checkin
          : 0;
      let percentage = 0;

      if (checkinUsers === 0) {
        return percentage;
      }
      percentage = Math.round((checkinUsers * 100) / totalUsers);
      return percentage;
    },
    registrationsOverTimeChartData() {
      if (!this.registrationsAndCheckinsOverTimeChartData) {
        return null;
      }
      let registrationsOverTime =
        this.registrationsAndCheckinsOverTimeChartData.registrations;

      let aux = {};

      for (let i = 0; i < registrationsOverTime.length; i++) {
        let el = registrationsOverTime[i];
        if (el.datevalue) {
          aux[el.datevalue] = el.total;
        }
      }


      return aux;
    },
    
    checkinsOverTimeChartData() {

      if (!this.registrationsAndCheckinsOverTimeChartData) {
        return null;
      }

      let checkinsOverTime =
        this.registrationsAndCheckinsOverTimeChartData.checkins;

      let aux = {};

      for (let i = 0; i < checkinsOverTime.length; i++) {
        let el = checkinsOverTime[i];
        if (el.datevalue) {
          aux[el.datevalue] = el.total;
        }
      }

      return aux;
    },
  },
  methods: {
    
    async loadChartData() {
      if (this.event && (this.event.sandia || this.event._id)) {
        this.registrationsAndCheckinsOverTimeChartData =
          await EventModel.getRegistrationsAndCheckinOverTime(
            this.event.sandia || this.event._id
          );
      }
    },
    
/*
    async loadChartData() {
      console.log('entro aqui')
      console.log('Event',await this.event )
      console.log('Sandia', this.event.sandia )
      console.log('Event ID', this.event._id)
      if (this.event && (this.event.sandia || this.event._id)) {
        this.registrationsAndCheckinsOverTimeChartData = {
          "registrations":[54],
          "checkins":[25]
        } //this.attendeesTotal.length , this.attendeesCheckinlength]

        console.log('Contiene todo',this.registrationsAndCheckinsOverTimeChartData)
      }
    },
*/
  },
  watch: {
    event() {
      this.loadChartData();
    },
  },
  async mounted() {
    await this.loadChartData();
  },
};