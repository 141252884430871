<template>
  <div id="lancome-reports">
    <!-- <h1>Reports</h1> -->
    <!-- <pre>{{event}}</pre>
        <pre>{{eventResults}}</pre> -->
    <div class="report-section">
      <div class="options-report"></div>
      <RegistrationBreakdown :event="event" />
      <QuestionsBreakdown :event="event" :eventResults="eventResults" />
      <AttendeeInfoBreakdown :event="event" />
      
      <!-- <pre>{{eventResults}}</pre> -->
    </div>
  </div>
</template>
<script>
import GameModel from "../../lib/GameModel";
import RegistrationBreakdown from "../partial/reports/RegistrationBreakdown.vue";
import QuestionsBreakdown from "../partial/reports/QuestionsBreakdown.vue";
import AttendeeInfoBreakdown from "../partial/reports/AttendeeInfoBreakdown.vue";

export default {
  props: ["event"],
  components: {
    RegistrationBreakdown,
    QuestionsBreakdown,
    AttendeeInfoBreakdown,
  },
  data() {
    return {
      eventResults: {},
    };
  },
  methods: {
    async runFunctionWithLoader(userFunction) {
      if (typeof userFunction === "function") {
        try {
          this.$emit("show_loader");
          await userFunction();
        } catch (error) {
          console.error(error);
          debugger;
        } finally {
          this.$emit("hide_loader");
        }
      }
    },
    async loadEventResults() {
      if (this.event) {
        this.eventResults = await GameModel.getEventGamesWithResults(
          this.event
        );
      }
    },
  },
  watch: {
    event() {
      this.loadEventResults();
    },
  },
  mounted() {
    this.loadEventResults();
  },
};
</script>
<style lang="scss">
#lancome-reports {
  margin-bottom: 80px;
  .options-report {
    display: block;
    width: 100%;
    height: 3em;
    //margin-top:10em;
  }
}
</style>
