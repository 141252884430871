var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"event-questions"}},[_c('div',{staticClass:"cta-container"},[_c('div',{staticClass:"info-container"},[_c('h1',[_vm._v("Messages")]),_c('p',[_vm._v(" "+_vm._s(_vm.totalMessages)+" Total Messages - Refreshing in: "+_vm._s(_vm.refreshTimer)+" ")])]),_c('div',{staticClass:"cta"},[_c('v-btn',{staticClass:"exportbtn",attrs:{"color":"#1976d2","large":""},on:{"click":function($event){return _vm.exportChat()}}},[_vm._v("Export Chat")])],1)]),_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filter-group stretch"},[_c('div',{staticClass:"filter stretch"},[_c('v-text-field',{attrs:{"color":"#CC4786","height":"40","outlined":"","block":"","prepend-inner-icon":"search","placeholder":"Enter search string"},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1)])]),_c('br'),_c('div',{staticClass:"results-table"},[_c('div',{staticClass:"results"},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventMessages,"options":_vm.options,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [50, 100, -1] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"question-delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteChatMessage(item)}}},'v-icon',attrs,false),on),[_vm._v("delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdAt))+" ")]}}])})]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }