<template>
  <div class="lancome-event-info-bar">
    <div class="content">
      <div class="back-button-container">
        <img
          @click="navigateToEventSelection"
          :src="backArrow"
          alt="Go Back"
          class="back-button"
        />
      </div>
      <div class="event-info-container">
        <h1>{{ event && event.name ? event.name : "Event name" }}</h1>
        <label v-if="event && event.startDate"
          >({{ event.startDate && event.startDate | formatDate }})</label
        >
        <label> | Active Users: {{ activeUsers }}</label>
      </div>
      <div class="link-container" v-if="activeLinks.length > 0">
        <router-link
          class="link"
          v-for="element in activeLinks"
          :key="element.uuid"
          :class="{ selected: element.selected }"
          :to="{
            name: element.link,
          }"
        >
          {{ element.label }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import backArrow from "../../assets/img/lancome_back_arrow.svg";
import Auth from "../../lib/Auth.js";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";

export default {
  props: ["event", "selected"],
  data() {
    return {
      prevRoute: null,
      activeUsers: 0,
      isSocketConnected: false,
      socket: io(CONSTANTS.SOCKET_SERVER_URL, {
        transports: ["websocket", "polling"],
      }),
      backArrow,
      isAdmin: 1,
      links: [
        {
          label: "Registrants",
          name: "registrations",
          link: `registrations`,
          active: true,
          selected: false,
        },
        {
          label: "Live Event - games",
          name: "games",
          link: `games`,
          active: true,
          selected: false,
        },
        {
          label: "Live Event - questions",
          name: "questions",
          link: `questions`,
          active: true,
          selected: false,
        },
        {
          label: "Chat Moderation",
          name: "chat",
          link: `chat-moderation`,
          active: true,
          selected: false,
        },
        {
          label: "Reports",
          name: "reports",
          link: `reports`,
          active: true,
          selected: false,
        },
      ],
    };
  },
  created() {
    this.fetchAdminRole();
    this.whenIsNotAdmin();
    console.log(this.links);
  },

  computed: {
    activeLinks() {
      return this.links.filter((el) => el.active);
    },
  },
  methods: {
    navigateToEventSelection() {
      // console.log(this.event.type)
      if(this.event.type === 'induction') {
        this.$router.push({ path: "/admin/inductions" });
      }else if(this.event.type.indexOf('seminar') >= 0){
        this.$router.push({ path: "/admin/seminars" });
      }else{
        this.$router.push({ path: "/admin/fragrances" });
      }
    },
    markLinkSelected(name) {
      this.links = this.links.forEach((el) => {
        el.selected = false;
      });
      let link = this.links.find((el) => el.name === name);
      link.selected = true;
    },
    fetchAdminRole() {
      const credentials = Auth.fetchCredentials();
      this.isAdmin = credentials.isAdmin;
    },
    whenIsNotAdmin() {
      if (this.isAdmin === "false") {
        this.links = [
          {
            label: "Registrants",
            name: "registrations",
            link: `registrations`,
            active: true,
            selected: false,
          },
        ];
      }
    },
    registerEvents() {
      if (!this.socket) {
        console.warn("NO SOCKET YET!!!");
        return;
      }

      this.socket.on("connect", (param) => {
        // alert('connected');
        console.log("connected");
        console.log(param);
      });

      // this.socket.emit('chat:signin', {
      //                                 type: 'signin',
      //                                 event: `lancome-admin-${this.event._id}`,
      //                                 id: 1
      //                             });

      // this.socket.on('chat:room',()=>{
      //         console.log('Connected to ');
      //         console.log(`lancome-admin-${this.event._id}`);
      //     });

      // // Reconnect
      // this.socket.on('reconnect', () => {
      //     // reconnect
      //     this.socket.emit('chat:signin',{
      //                                 type: 'reconnect',
      //                                 event: `lancome-admin-${this.event._id}`,
      //                                 id: 1
      //                             });
      // });

      this.socket.on("admin:users:counter", (data) => {
        if (!data) {
          return;
        }
        try {
          this.activeUsers = data.counter || 0;
        } catch (err) {
          console.error(err);
        }
      });
      console.log("subscribed");
    },
  },
  watch: {
    event() {
      this.registerEvents();
    },
  },
  mounted() {
    if (typeof this.selected === "string") {
      this.markLinkSelected(this.selected);
    }
    setTimeout(() => {
      this.registerEvents();
    }, 500);
  },
};
</script>
<style lang="scss">
.lancome-event-info-bar {
  position: fixed;
  z-index: 10;
  width: 100%;

  background-color: #464646;
  display: flex;
  justify-content: center;
  .content {
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    height: 40px;

    .back-button-container {
      &:hover {
        cursor: pointer;
      }
    }

    .event-info-container {
      display: flex;
      flex: 1;
      h1 {
        font-family: graphikmedium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 10px;
        color: #ffffff;
      }

      label {
        font-family: graphikregular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 5px;
        color: #ffffff;
      }
    }

    .link-container {
      .link {
        font-family: graphikmedium;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;

        color: white !important;
        text-decoration: none;
        padding: 0 5px;
        margin: 0 2px;
        border-bottom: 2px solid transparent;

        &.selected,
        &:hover,
        &.router-link-active {
          border-color: #f7f9f9;
        }
      }
    }
  }
}
</style>
