<template>
  <div id="event-questions">
    <!-- {{event.attendees}} -->
    <!-- <h1>Registrations</h1> -->
    <div class="cta-container">
      <div class="info-container">
        <h1>Registrants</h1>
        <p>{{ totalQuestions }} Total Questions</p>
      </div>

      <div class="cta">
        <v-btn class="exportbtn" color="#1976d2" large @click="exportQuestions()"
          >Export Questions</v-btn
        >
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-group stretch">
        <div class="filter stretch">
          <v-text-field
            v-model="queryString"
            color="#CC4786"
            height="40"
            outlined
            block
            prepend-inner-icon="search"
            placeholder="Find by name or question"
          ></v-text-field>
        </div>
        <div class="filter">
          <v-select
            color="#CC4786"
            v-model="questionStatus"
            height="40"
            outlined
            block
            :items="statusFilter"
            placeholder="Status"
            item-text="label"
            return-object
          ></v-select>
        </div>
        <div class="filter">
          <v-select
            color="#CC4786"
            v-model="favoriteStatus"
            height="40"
            outlined
            block
            :items="favoriteFilter"
            placeholder="Highlighted"
            item-text="label"
            return-object
          ></v-select>
        </div>
      </div>
    </div>
    <br />
    <!-- <div class="no-data" v-if="attendees.length === 0">
            <h1>No results found</h1>
        </div> -->
    <!-- {{order_by}} - {{order_dir}} -->
    <div class="results-table">
      <!-- <p>Total Results: {{totalResults}}</p> -->
      <div class="results">
        <template>
          <v-data-table
            :headers="headers"
            :items="eventQuestions"
            :options.sync="options"
            :loading="loading"
            class="elevation-0"
          >
            <template v-slot:item.email="{ item }">
              <a :href="'mailto:' + item.email">{{ item.email }}</a>
            </template>

            <template v-slot:item.favorite="{ item }">
              <!-- <v-checkbox
                            color="#CC4786"
                            :input-value="item.favorite"
                            @click.stop="updateFavoriteStatus(item)"
                            :label="item.favorite?'fav':'reg'"
                        ></v-checkbox> -->
              <!-- {{item.favorite}} -->
              <v-icon
                @click.stop="updateFavoriteStatus(item)"
                class="question-status"
                :class="{ highlighted: item.favorite }"
                >mdi-star</v-icon
              >
            </template>

            <template v-slot:item.status="{ item }">
              <v-checkbox
                color="#CC4786"
                :input-value="item.status === 'answered'"
                @click.stop="updateStatus(item)"
              ></v-checkbox>
            </template>

            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | formatDateTime }}
            </template>
          </v-data-table>
        </template>
      </div>

      <!-- <div class="controls">
                <div class="result-amount">
                    <label>Displaying</label>
                    <v-select
                        outlined
                        color="#CC4786"
                        v-model="displayResults"
                        height="40"
                        :items="displayResultsOptions"
                    ></v-select>
                </div>
                <div class="pagination">
                    <v-pagination
                        class="elevation-0"
                        color="#CC4786"
                        v-model="currentPage"
                        :length="availablePages"
                        :total-visible="7"
                    ></v-pagination>
                </div>
                <div class="go-to-page">
                    <label>Page</label>
                    <v-select
                        outlined
                        color="#CC4786"
                        v-model="currentPage"
                        height="40"
                        :items="availablePagesList"
                    ></v-select>
                </div>
            </div> -->
    </div>
  </div>
</template>
<script>
import EventModel from "../../lib/EventModel";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";
import axios from "axios";
export default {
  props: ["event"],
  data() {
    return {
      CONSTANTS,
      queryString: "",
      questionStatus: "",
      favoriteStatus: "",
      statusFilter: ["all", "pending", "answered"],
      favoriteFilter: ["all", "highlighted"],
      awaitingSearch: false,
      options: {},
      headers: [
        { text: "Created At", value: "createdAt", sortable: true },
        { text: "User", value: "user", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Question", value: "message", sortable: true },
        {
          text: "Highlighted",
          value: "favorite",
          sortable: true,
          align: "center",
        },
        { text: "Status", value: "status", sortable: true, align: "center" },
      ],
      eventQuestions: [],
      loading: false,
      socket: io(CONSTANTS.SOCKET_SERVER_URL, {
        transports: ["websocket", "polling"],
      }),
      // timer:null
    };
  },

  computed: {
    totalQuestions() {
      return this.eventQuestions.length;
    },
  },
  methods: {
    async loadQuestionData() {
      if (!this.event || !this.event._id) {
        return;
      }
      try {
        this.loading = true;
        let filter = {};
        if (this.queryString.trim().length > 0) {
          filter.query = this.queryString;
        }

        if (
          this.questionStatus.trim().length > 0 &&
          this.questionStatus !== "all"
        ) {
          filter.status = this.questionStatus;
        }

        if (this.favoriteStatus === "highlighted") {
          filter.highlighted = true;
        }

        this.eventQuestions = await EventModel.getEventQuestions(
          this.event,
          filter
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async updateStatus(item) {
      //alert(JSON.stringify(item));
      let newStatus = item.status === "answered" ? "pending" : "answered";
      item.status = newStatus;
      await EventModel.updateQuestionStatus(item);

      // alert('emitting');
      if (!this.loading) {
        console.log(
          this.socket.emit("message:update", {
            _id: item._id,
            event: this.event._id,
            status: newStatus,
          })
        );
      }
    },
    async updateFavoriteStatus(item) {
      //alert(JSON.stringify(item));
      let newStatus = item.favorite ? false : true;
      item.favorite = newStatus;

      await EventModel.updateQuestionFavoriteStatus(item);

      if (!this.loading) {
        console.log(
          this.socket.emit("message:update", {
            _id: item._id,
            event: this.event._id,
            favorite: newStatus,
          })
        );
      }
    },

    registerEvents() {
      if (!this.socket) {
        console.warn("NO SOCKET YET!!!");
        return;
      }

      this.socket.on("connect", (param) => {
        console.log("connected");
        console.log(param);
      });

      this.socket.emit("chat:signin", {
        type: "signin",
        event: `lancome-admin-${this.event._id}`,
        id: 1,
      });

      this.socket.on("chat:room", () => {
        console.log("Connected to ");
        console.log(`lancome-admin-${this.event._id}`);
      });
      // Reconnect
      this.socket.on("reconnect", () => {
        this.socket.emit("chat:signin", {
          type: "reconnect",
          event: `lancome-admin-${this.event._id}`,
          id: 1,
        });
      });

      this.socket.on("message:received", async () => {
        this.loading = true;
        await this.loadQuestionData();
        this.loading = false;
      });
      this.socket.on("message:updated", async (data) => {
        try {
          this.loading = true;

          if (!Array.isArray(this.eventQuestions)) {
            this.eventQuestions = [];
          }
          let questions = [...this.eventQuestions];
          let position = questions.findIndex((el) => el._id === data._id);
          if (position > -1) {
            questions[position] = data;
            this.$set(questions, position, data);
          }
          await this.loadQuestionData();
        } finally {
          this.loading = false;
        }
      });
    },

    exportQuestions() {

      let url_id = this.$route.params.event_id;

      axios
        .request({
          url:
            this.CONSTANTS.API_MONGO_BASE_URL +
            "api/reports/messages?event=" +
            url_id,
          method: "GET",
          headers: { "x-token-auth": localStorage.getItem("token") },
          responseType: "blob",
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "Questions.xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    queryString() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.currentPage = 1;
          this.loadQuestionData();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    questionStatus(newVal) {
      if (newVal === "all") {
        this.questionStatus = "";
      }
      this.loadQuestionData();
    },
    favoriteStatus(newVal) {
      if (newVal === "all") {
        this.favoriteStatus = "";
      }
      this.loadQuestionData();
    },

    options: {
      handler(newVal) {
        // console.log(this.options);
        // debugger;
        this.order_by =
          newVal && newVal.sortBy && newVal.sortBy[0]
            ? newVal.sortBy[0]
            : "email";
        this.order_dir =
          newVal && newVal.sortDesc && newVal.sortDesc[0] ? "DESC" : "ASC";
        this.loadQuestionData();
      },
      deep: true,
    },
    event() {
      if (event) {
        this.loadQuestionData();
      }
    },
  },
  async mounted() {
    await this.loadQuestionData();
    this.registerEvents();
    // this.timer = setTimeout(()=>{ this.show = false; console.log('Timer');}, 5000);
    //this.totalResults = this.attendees.length;
  },
};
</script>
<style lang="scss">
#event-questions {
  margin-bottom: 80px;

  .question-status {
    &:hover,
    &.highlighted {
      cursor: pointer;
      color: #fb8d00;
    }
  }

  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #e4e8e8;
  }
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #e4e8e8;
  }

  margin: 43px 0 0 auto;
  // width: 90%;
  // max-width: 1200px;
  .cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;

    .info-container {
      margin-top: 5em;
      flex: 1;
      display: flex;
      justify-content: center;
      flex-flow: column;
      h1 {
        font-family: graphikmedium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #363738;
        margin: 0;
      }
      p {
        font-family: graphikregular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #6c6e6f;
        margin: 0;
      }
    }

    .cta {
      margin-top: 5em;
    }
  }

  .filter-container {
    margin-top: 5px;
    display: flex;
    flex-flow: row wrap;
    .filter-group {
      display: flex;
      flex-flow: row wrap;
      .filter {
        display: flex;
        flex-flow: row wrap;
        margin-left: 5px;

        .v-text-field__details {
          display: none;
        }

        .v-input__slot {
          background-color: white;
        }

        .v-input__prepend-inner {
          z-index: 10;
        }
      }
    }
  }

  .single-radio-input {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e8e8;
    background-color: white;
    padding: 0 12px;
    border-radius: 4px;
  }

  .v-input--selection-controls__input {
    .v-label {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 140%;
    }
  }

  .stretch {
    flex-grow: 1;
    flex-shrink: 0;
  }
  .noshrink {
    flex-shrink: 0;
  }

  .outlined {
    border: 1px solid red;
    border-radius: 50%;
    height: 56px;
    width: 56px;
  }

  .no-data {
    border: 1px solid #e4e8e8;
    background-color: white;
    padding: 0 12px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;

    h1 {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;
    }
  }

  .controls {
    margin-top: 25px;
    display: flex;
    .result-amount,
    .go-to-page {
      width: 10%;
    }
    .pagination {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .result-amount {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      display: flex;
      align-items: center;
      label {
        margin-right: 5px;
      }

      .v-text-field__details {
        display: none;
      }
      .mdi-menu-down::before {
        background: none;
        width: 0;
        height: 0;
      }
      .v-input__append-inner {
        display: none;
      }
    }
    .go-to-page {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      display: flex;
      align-items: center;
      label {
        margin-right: 5px;
      }

      .v-text-field__details {
        display: none;
      }
      .mdi-menu-down::before {
        background: none;
        width: 0;
        height: 0;
      }
      .v-input__append-inner {
        display: none;
      }
    }

    .v-pagination__navigation,
    .v-pagination__item {
      box-shadow: none;
      background-color: transparent;
      outline: none;
    }
    .v-pagination__item.v-pagination__item--active {
      color: #1976d2;
      background-color: transparent !important;
      // text-decoration: underline;
      border-bottom: 1px solid #1976d2;
      border-radius: 0;
    }

    .v-icon.notranslate.mdi.mdi-chevron-left,
    .v-icon.notranslate.mdi.mdi-chevron-right {
      color: #1976d2;
    }
  }

  th {
    background-color: #e4e8e8;
    text-transform: uppercase;
    font-family: graphikmedium;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.25em;
    text-align: center;
    color: #1976d2;
  }

  td {
    font-family: graphikregular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .registered,
  .confirmed {
    color: #23adfa;
  }

  .checkin {
    color: #19ccac;
  }

  .create-edit-form {
    background: #ffffff;
    // box-shadow: 0px 20px 40px rgba(255, 240, 250, 0.8);
    border-radius: 8px;
    padding: 25px;

    width: 95vw;
    max-width: 465px;

    .title {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i:hover {
        cursor: pointer;
      }
      h1 {
        color: #363738;
        font-family: graphikmedium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: capitalize;
      }
    }
    .lancome-textfield label {
      color: #363738;
    }
    .form-input > input {
      width: 100%;
    }

    .v-application.error,
    .lancome-textfield.error {
      background-color: transparent !important;
      label {
        color: red !important;
      }
    }
    color: #363738;
    *::placeholder {
      color: A2A9A9, 100%;
    }
    .v-select__selections > * {
      color: #363738;
      *::placeholder {
        color: A2A9A9, 100%;
      }
    }

    .v-input__control {
      color: #e4e8e8;
    }

    label {
      font-family: graphikmedium;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #363738;
      display: block;
    }

    .goals {
      display: flex;
      justify-content: row wrap;
      .col {
        min-width: 50%;
      }

      .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
        > .v-input__control
        > .v-input__slot
        fieldset {
        color: #e4e8e8;
      }
      .v-icon.v-icon {
        color: #e4e8e8;
      }
      .v-messages.theme--light {
        display: none;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
    }

    .v-input--checkbox {
      .v-label {
        font-family: graphikregular;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        color: #363738;
      }
    }

    .controls {
      display: flex;
      justify-content: space-between;

      .v-btn.v-btn--flat.v-btn--plain {
        span {
          color: #b6bdbd !important;
        }
      }
    }
  }
}
</style>
