<template>
  <div class="lancome-admin-main">
    <NavBar />
    <Loading :show="showLoader" />
    <div class="info-container">
      <router-view
        v-on:show_loader="showLoader = true"
        v-on:hide_loader="showLoader = false"
        :key="$route.path"
      />
    </div>

  </div>
</template>
<script>
import NavBar from "../components/NavBar/NavBar.vue";
import "animate.css";
import Loading from "../components/Loading/Loading.vue";

export default {
  components: {
    NavBar,
    Loading,
  },
  data() {
    return {
      showLoader: false,
    };
  },

  async mounted() {
    //console.log(this.$route.path);
    try {
      this.showLoader = true;
    } catch (error) {
      console.error(error);
      debugger;
    } finally {
      this.showLoader = false;
    }
  },
};
</script>
<style lang="scss">
.lancome-admin-main {
  .animated-element {
    transition: all 5s;
  }
}
</style>
