var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"event-questions"}},[_c('div',{staticClass:"cta-container"},[_c('div',{staticClass:"info-container"},[_c('h1',[_vm._v("Registrants")]),_c('p',[_vm._v(_vm._s(_vm.totalQuestions)+" Total Questions")])]),_c('div',{staticClass:"cta"},[_c('v-btn',{staticClass:"exportbtn",attrs:{"color":"#1976d2","large":""},on:{"click":function($event){return _vm.exportQuestions()}}},[_vm._v("Export Questions")])],1)]),_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filter-group stretch"},[_c('div',{staticClass:"filter stretch"},[_c('v-text-field',{attrs:{"color":"#CC4786","height":"40","outlined":"","block":"","prepend-inner-icon":"search","placeholder":"Find by name or question"},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1),_c('div',{staticClass:"filter"},[_c('v-select',{attrs:{"color":"#CC4786","height":"40","outlined":"","block":"","items":_vm.statusFilter,"placeholder":"Status","item-text":"label","return-object":""},model:{value:(_vm.questionStatus),callback:function ($$v) {_vm.questionStatus=$$v},expression:"questionStatus"}})],1),_c('div',{staticClass:"filter"},[_c('v-select',{attrs:{"color":"#CC4786","height":"40","outlined":"","block":"","items":_vm.favoriteFilter,"placeholder":"Highlighted","item-text":"label","return-object":""},model:{value:(_vm.favoriteStatus),callback:function ($$v) {_vm.favoriteStatus=$$v},expression:"favoriteStatus"}})],1)])]),_c('br'),_c('div',{staticClass:"results-table"},[_c('div',{staticClass:"results"},[[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.eventQuestions,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.email}},[_vm._v(_vm._s(item.email))])]}},{key:"item.favorite",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"question-status",class:{ highlighted: item.favorite },on:{"click":function($event){$event.stopPropagation();return _vm.updateFavoriteStatus(item)}}},[_vm._v("mdi-star")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"#CC4786","input-value":item.status === 'answered'},on:{"click":function($event){$event.stopPropagation();return _vm.updateStatus(item)}}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdAt))+" ")]}}])})]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }