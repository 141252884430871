import logoysl_white from "../../assets/img/yslnavbar.png";
import logoga_white from "../../assets/img/ganavbar.png";
import Auth from "../../lib/Auth.js";
import CONSTANTS from "../../lib/Constants.js";
import axios from "axios";

export default {
  props: ["selected"],
  data() {
    return {
      CONSTANTS,
      logoysl_white,
      logoga_white,
      currentUser:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
      stats: [
        {
          label: "Events",
          value: 0,
          isPercent: false,
        },
        {
          label: "Total Registrations",
          value: 0,
          isPercent: false,
        },
      ],
      links: [
        {
          label: "Fragrances",
          name: "fragrances",
          link: `fragrances`,
          active: true,
          selected: false,
        },  
        {
          label: "Seminars",
          name: "seminars",
          link: `seminars`,
          active: true,
          selected: false,
        },
        {
          label: "Inductions",
          name: "inductions",
          link: `inductions`,
          active: true,
          selected: false,
        },
      ],
      loading: false,
    };
  },
  computed: {
    activeLinks() {
      return this.links.filter((el) => el.active);
    },
  },
  methods: {
    handleLogout() {
      Auth.clearCredentials();
      this.$router.push({ name: "Login" });
    },
    fetchStats() {
      try {
        let url = this.CONSTANTS.SANDIA_API_BASE + "attendee/count";

        axios
          .post(url, null, null)
          .then(async (result) => {
            // console.log(result);
            if (result.data) {
              let data = result.data;
              // console.log(data);

              let eventTotal = 0;
              let totalRegistrations = 0;

              //Get event total
              try {
                eventTotal = data
                  .map((el) => el.event)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  ).length;

              } catch (e) {
                console.error(e);
                eventTotal = 0;
              }
              //console.log(eventTotal);
              this.stats[0].value = eventTotal;

              //Get total registrations
              try {
                totalRegistrations = data.reduce((total, entry) => {
                  return total + entry.attendees;
                }, 0);
              } catch (e) {
                console.error(e);
                totalRegistrations = 0;
              }
              // console.log(eventTotal);
              this.stats[1].value = totalRegistrations;
            }
          })
          .catch((error) => {
            // error.response.data.error.detail || "Error: Invalid credentials";
            alert("An error has ocurred");
            console.log(error);
          });
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.fetchStats();
  },
};
