import TextInput from "../../components/TextInput/TextInput.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import EventModel from "../../lib/EventModel";
import lancome_stores from "../../lib/lancome_stores.json";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";
import axios from "axios";
import XLSX from "xlsx";

export default {

  components: { TextInput },
  props: ["event"],
  data() {
    return {
      lancome_stores,
      isLoadingInfo: false,
      eventInfo: {
        registrations: 3470,
        checkins: 2673,
      },
      stores: [{ name: "All" }, { name: "A" }],
      selectedStore: null,
      queryString: "",
      switchCheckins: false,
      switchRegistered: false,
      attendees: [{ enable: false }],
      displayResults: 10,
      currentPage: 1,
      totalResults: 0,
      displayResultsOptions: [10, 25, 50, 100],
      overlay: "display:none",
      events: null,
      selectedAttendeeID: 0,
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        // { text: "Store Name", value: "store" },
        // { text: "STE", value: "ste" },
        { text: "Status", value: "status" },
        { text: "Banned", value: "block", sortable: false },
        { text: "Link", value: "rsvp", sortable: false },
        // { text: "See Details", value: "id", sortable: false },
      ],
      loading: false,
      options: {},
      showCreateDialog: false,
      mode: "create",
      eventsUpdate: [],
      //Form Variables
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      retailer: "",
      accountexecutive: "",
      position: "",
      touche: "",
      fragancechoice: "",
      foundation: "",
      concealer: "",
      store: "",
      skinwish: "",
      formStore: null,
      formGoals: [],
      registrationStatus: "",
      awaitingSearch: false,
      order_by: "email",
      order_dir: "DESC",
      event_id: null,
      socket: io(CONSTANTS.SOCKET_SERVER_URL, {
        transports: ["websocket", "polling"],
      }),
    };
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
    },
    lastName: {
      required,
      minLength: minLength(4),
    },
    email: {
      minLength: minLength(4),
      email,
    },
    phone: {
      minLength: minLength(4),
    },
    formGoals: {
      required,
    },
  },
  computed: {
    availablePages() {
      return Math.ceil(this.totalResults / this.displayResults);
    },
    availablePagesList() {
      let pages = [];
      for (let i = 0; i < this.availablePages; i++) {
        pages.push(i + 1);
      }
      return pages;
    },
    firstNameError() {
      return this.getErrorMessage(this.$v.firstName);
    },
    lastNameError() {
      return this.getErrorMessage(this.$v.lastName);
    },
    emailError() {
      return this.getErrorMessage(this.$v.email);
    },
    phoneError() {
      return this.getErrorMessage(this.$v.phone);
    },
  },
  methods: {
    showLink(item) {

      const urlSearch = window.location.href.includes('cms-');

      const startDate = new Date(this.event.startDate);
      const startMonth = startDate.getMonth() > 9 ? startDate.getMonth() + 1 : `0${startDate.getMonth() + 1}`;
      const start = Number(`${startDate.getFullYear()}${startMonth}`);

      const newId = (start > 202403) ? this.event.sandia : this.event._id;

      if (urlSearch) {
        const url = `https://staging.yslarmaniseminars.com/events/${newId}?rsvp=${item.rsvp}`;
        window.open(url, '_blank');
      } else {
        const url = `https://yslarmaniseminars.com/events/${newId}?rsvp=${item.rsvp}`;
        window.open(url, '_blank');
      }

    },

    showCreateNewDialog() {
      this.mode = "create";

      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.formStore = null;
      this.formGoals = [];

      this.$v.firstName.$reset();
      this.$v.lastName.$reset();
      this.$v.email.$reset();
      this.$v.phone.$reset();

      this.showCreateDialog = true;
    },
    registerEvents() {
      if (!this.socket) {
        console.warn("NO SOCKET YET!!!");
        return;
      }

      this.socket.on("connect", (param) => {
        console.log("connected");
        console.log(param);
      });

      this.socket.emit("chat:signin", {
        type: "signin",
        event: `lancome-admin-${this.event._id}`,
        id: 1,
      });

      this.socket.on("chat:room", () => {
        console.log("Connected to ");
        console.log(`lancome-admin-${this.event._id}`);
      });
      // Reconnect
      this.socket.on("reconnect", () => {
        this.socket.emit("chat:signin", {
          type: "reconnect",
          event: `lancome-admin-${this.event._id}`,
          id: 1,
        });
      });
    },
    async fetchResults() {
      console.log(this.options);

      try {
        let filter = {};
        console.log(filter);
      } catch (e) {
        debugger;
        console.error(e);
      }

      this.loading = false;
    },
    getErrorMessage(model) {
      if (model == null || !model.$dirty) {
        return "";
      }

      let message = "";
      // debugger;
      if (typeof model.required !== "undefined" && !model.required) {
        message = "This field is required";
      }
      if (typeof model.minLength !== "undefined" && !model.minLength) {
        message =
          "You must enter at least " +
          model.$params.minLength.min +
          " characters";
      }
      if (
        typeof model.sameAsPassword !== "undefined" &&
        !model.sameAsPassword
      ) {
        message = "Entered value doesn't match";
      }
      if (typeof model.email !== "undefined" && !model.email) {
        message = "Value must be valid email";
      }

      return message;
    },
    isFormValid() {
      let isValid = true;

      if (this.$v.email && this.$v.email.$invalid) {
        isValid = false;
      }
      if (this.$v.password && this.$v.password.$invalid) {
        isValid = false;
      }
      console.log(this.$v);
      return isValid;
    },
    async handleFormSubmit() {
      let store = this.formStore ? this.formStore.value : "";
      let goals =
        this.formGoals &&
          Array.isArray(this.formGoals) &&
          this.formGoals.length > 0
          ? this.formGoals.join()
          : "";

      let attendee = {
        firstname: this.firstName.trim(),
        lastname: this.lastName.trim(),
        email: this.email.trim() || "",
        event: this.event.sandia || this.event._id,
        phone: this.phone || "",
        store: store,
        skinwish: goals,
      };
      // console.log(attendee);
      // debugger;
      await this.runFunctionWithLoader(async () => {
        await EventModel.addNewAttendee(attendee);
        this.showCreateDialog = false;
      });
    },

    async updateBanStatus(item) {
      // alert(item.id);
      let event_id = this.event._id;
      let attendee_id = item.id;

      if (
        item.block === "true" ||
        item.block === true ||
        item.block === "1" ||
        item.block === 1
      ) {
        await EventModel.blockUser(item, false);
        item.block = 0;

        this.socket.emit("attendee:unlock", {
          event: event_id,
          id: attendee_id,
        });

      } else {
        await EventModel.blockUser(item, true);
        item.block = 1;

        this.socket.emit("attendee:block", {
          event: event_id,
          id: attendee_id,
        });
      }
    },

    async getFilteredAttendeeInfo() {

      let firstRecord = (this.currentPage - 1) * this.displayResults || 0;
      let status =
        this.registrationStatus &&
          this.registrationStatus.toLowerCase() !== "all"
          ? this.registrationStatus.toLowerCase()
          : "";
      let store =
        this.selectedStore &&
          typeof this.selectedStore.value === "string" &&
          this.selectedStore.value.toLowerCase() !== "all"
          ? this.selectedStore.value.trim()
          : "";
      // let order_by = (this.object && this.object.sortBy && this.object.sortBy[0])?this.object.sortBy[0]:'email';
      // let order_dir = (this.object && this.object.sortDesc && this.object.sortDesc[0] && this.object.sortDesc[0] === false)?'ASC':'DESC';
      // debugger;

      let filter = {
        first_record: firstRecord,
        record_limit: this.displayResults,
        order_by: this.order_by,
        order_dir: this.order_dir,
        term: this.queryString,
        status: status,
        store: store,
      };

      let count, users;

      let sandiaEvent = this.event.sandia || this.event._id;

      try {
        //  ({ count, users } = await EventModel.getFilteredAttendees(
        //     this.event.sandia,
        //     filter
        //   ));
        let filteredResponse = await EventModel.getFilteredAttendees(
          sandiaEvent,
          filter
        );

        count = filteredResponse.count || 0;
        users = filteredResponse.users || 0;
        debugger;
      } catch (e) {
        if (e) {
          console.log(e);
          debugger;
        }
      }

      this.totalResults = count || 0;
      this.attendees = users || [];
      console.log(this.attendees);
      this.event_id = this.event._id;
    },
    async updateEventAttendeeInfo() {
      if (!this.event) {
        return;
      }
      let attendees = await EventModel.getAttendeeCount(
        this.event.sandia || this.event._id
      );
      this.$set(this.event, "attendees", attendees);
    },
    async refreshAttendeeInfo() {
      const eventId = this.event.sandia || this.event._id;

      if (this.loading || !this.event || !eventId) {
        return;
      }

      this.loading = true;

      // await this.runFunctionWithLoader(this.getFilteredAttendeeInfo);
      await this.getFilteredAttendeeInfo();
      await this.updateEventAttendeeInfo();
      this.loading = false;
    },

    async runFunctionWithLoader(userFunction) {
      if (typeof userFunction === "function") {
        try {
          this.$emit("show_loader");
          await userFunction();
        } catch (error) {
          console.error(error);
          debugger;
        } finally {
          this.$emit("hide_loader");
        }
      }
    },
    showSeeDetailsForm(attendeeID) {
      const url = CONSTANTS.SANDIA_API_BASE + "attendee/" + attendeeID;
      this.selectedAttendeeID = attendeeID;
      for (let a = 0; a < this.events.length; a++)
        this.events[a].enable = false;

      axios
        .post(url, {})
        .then((result) => {
          let attendees = result.data;
          this.firstName = attendees[0].firstname;
          this.lastName = attendees[0].lastname;
          this.email = attendees[0].email;
          this.phone = attendees[0].phone;
          this.formStore = {
            label: attendees[0].store,
            value: attendees[0].store,
          };
          this.skinwish = attendees[0].skinwish;
          if (attendees[0].skinwish != null)
            this.formGoals = attendees[0].skinwish.split(",");
          else this.formGoals = [];

          for (let i = 0; i < attendees.length; i++) {
            for (let a = 0; a < this.events.length; a++) {
              if (
                attendees[i].event_id == this.events[a].sandia ||
                attendees[i].event_id == this.events[a]._id
              ) {
                this.events[a].enable = true;
              }
            }
          }
          //this.eventsUpdate = ;
          this.overlay = "display:flex;";
        })
        .catch((e) => {
          console.log(e);
          alert("there was an error please try later!");
        });
    },
    closeSeeDetailsForm() {
      this.overlay = "display:none";
      this.selectedAttendeeID = 0;
    },
    getEvents() {

      let url = CONSTANTS.API_MONGO_BASE_URL + "api/events";

      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      axios
        .get(url, config)
        .then(async (result) => {
          if (result.data) {

            let tempEvent = result.data.data.items.filter((el) => {

              if (el._id == this.event._id) {
                this.$set(el, "enable", true);
              } else {
                this.$set(el, "enable", false);
              }

              if (el.active) {
                return el;
              }

            });

            this.events = tempEvent;
            this.eventsUpdate = tempEvent;

            await Promise.all(
              this.events.map(async (event) => {
                let attendees = await EventModel.getAttendeeCount( event.sandia || event._id );
                this.$set(event, "attendees", attendees);
              })
            );
          }
        })
        .catch((error) => {
          alert("An error has ocurred");
          console.error(error);
        });

    },
    changeAttendeeEventSave() {
      this.updateAttendee();
      for (let i = 0; i < this.events.length; i++) {
        this.changeAttendeeEvent(i);
      }
      this.closeSeeDetailsForm();
    },
    changeAttendeeEvent(eventArrayPosition) {
      let uri = "";
      const eventId =
        this.events[eventArrayPosition].sandia ||
        this.events[eventArrayPosition]._id;
      if (this.events[eventArrayPosition].enable) {
        //dar de alta
        uri = `${this.selectedAttendeeID}/addevent/${eventId}`;
      } else {
        //dar de baja
        uri = `${this.selectedAttendeeID}/removeevent/${eventId}`;
      }
      this.updateAttendeeEventStatus(uri);
    },
    updateAttendeeEventStatus(uri) {
      const url = CONSTANTS.SANDIA_API_BASE + "attendee/" + uri;

      axios
        .post(url, {})
        .then(() => {
          if (uri.indexOf("removeevent") > -1) this.refreshAttendeeInfo();
        })
        .catch((e) => {
          console.log(e);
          alert("there was an error please try later!");
        });
    },

    async exportRegistrants() {
      let exportAttendees = [];

      let count,
        users = 0;

      let filter = {
        first_record: 0,
        record_limit: 1000000,
        order_by: this.order_by,
        order_dir: this.order_dir,
        term: this.queryString,
      };

      try {
        ({ count, users } = await EventModel.getFilteredAttendees(
          this.event.sandia || this.event._id,
          filter
        ));
      } catch (e) {
        if (e) {
          console.log(e);
        }
      }
      console.log(count);
      if (this.event.type === 'induction') {
        if (this.event.brand === 'ysl') {
          for (let i = 0; i < users.length; i++) {
            exportAttendees.push({
              "First Name": users[i].first_name,
              "Last Name": users[i].last_name,
              Email: users[i].email,
              Phone: users[i].phone,
              Address: users[i].address,
              "Address 2": users[i].address_2,
              City: users[i].city,
              State: users[i].state,
              "Zip-Code": users[i].zip,
              Retailer: users[i].retailer,
              "Account Executive": users[i].accountexecutive,
              Position: users[i].position,
              Touche: users[i].touche,
              "Fragrance Choice": users[i].fragancechoice,
              Banned: users[i].block == 0 ? "No" : "Yes",
              Status: users[i].status,
              "Registered Date": users[i].register_date,
              watchGroup: users[i].watchGroup ? 'YES' : 'NO',
              groupOwner: users[i].groupOwner || '',
              "Metadata": users[i].metadata || ''
            });
          }
        } else {
          for (let i = 0; i < users.length; i++) {
            exportAttendees.push({
              "First Name": users[i].first_name,
              "Last Name": users[i].last_name,
              Email: users[i].email,
              Phone: users[i].phone,
              Address: users[i].address,
              "Address 2": users[i].address_2,
              City: users[i].city,
              State: users[i].state,
              "Zip-Code": users[i].zip,
              Retailer: users[i].retailer,
              "Account Executive": users[i].accountexecutive,
              Position: users[i].position,
              Foundation: users[i].foundation,
              Concealer: users[i].concealer,
              "Fragrance": users[i].fragancechoice,
              Banned: users[i].block == 0 ? "No" : "Yes",
              Status: users[i].status,
              "Registered Date": users[i].register_date,
              watchGroup: users[i].watchGroup ? 'YES' : 'NO',
              groupOwner: users[i].groupOwner || '',
              "Metadata": users[i].metadata || ''
            });
          }
        }
      }

      if (this.event.type.indexOf('seminar') >= 0 || this.event.type === 'fragrance') {
        for (let i = 0; i < users.length; i++) {
          exportAttendees.push({
            "First Name": users[i].first_name,
            "Last Name": users[i].last_name,
            Email: users[i].email,
            Phone: users[i].phone,
            Address: users[i].address,
            "Address 2": users[i].address_2,
            City: users[i].city,
            State: users[i].state,
            "Zip-Code": users[i].zip,
            Retailer: users[i].retailer,
            "Account Executive": users[i].accountexecutive,
            Position: users[i].position,
            Banned: users[i].block == 0 ? "No" : "Yes",
            Status: users[i].status,
            "Registered Date": users[i].register_date,
            "Custom Question 1": users[i].custom_question1,
            "Custom Question 2": users[i].custom_question2,
            "Custom Question 3": users[i].custom_question3,
            watchGroup: users[i].watchGroup ? 'YES' : 'NO',
            groupOwner: users[i].groupOwner || '',
            "Metadata": users[i].metadata || ''
          });
        }
      }



      const Registrants = XLSX.utils.json_to_sheet(exportAttendees);
      const workBook = {
        Title: "hello",
        SheetNames: ["Registrants"], // sheet name
        Sheets: {
          Registrants: Registrants,
        },
      };

      XLSX.writeFile(workBook, "Registrants.xlsx");
    },
    phoneNumberMask(e) {
      let ph = this.phone.replace(/\D/g, "").substring(0, 10);

      const deleteKey = e ? e.keyCode === 8 || e.keyCode === 46 : false;
      const len = ph.length;
      if (len > 0 && len < 3) {
        ph = `(${ph}`;
      } else if (len === 3) {
        ph = `(${ph}${deleteKey ? "" : ") "}`;
      } else if (len < 6) {
        ph = `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}`;
      } else if (len === 6) {
        ph = `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}${deleteKey ? "" : "-"
          }`;
      } else {
        ph = `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}-${ph.substring(
          6,
          10
        )}`;
      }

      if (String(ph).trim() === "()") {
        ph = "";
      }
      this.phone = ph;
    },
    updateAttendee() {
      const skinwishpref = this.formGoals.join(",");
      let attendee = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        phone: this.phone,
        store: this.formStore.value,
        skinwish: skinwishpref,
      };
      const url =
        CONSTANTS.SANDIA_API_BASE + `attendee/${this.selectedAttendeeID}/edit`;

      axios
        .post(url, attendee)
        .then(() => {
          this.refreshAttendeeInfo();
        })
        .catch((e) => {
          console.log(e);
          alert("there was an error please try later!");
        });

      this.closeSeeDetailsForm();
    },
    //lastmethod
  },
  watch: {
    selectedStore(newVal) {
      if (newVal && newVal.label === "All") {
        this.selectedStore = null;
      }
      this.currentPage = 1;
      this.refreshAttendeeInfo();
    },
    registrationStatus(newVal) {
      if (newVal && newVal.toLowerCase() === "all") {
        this.registrationStatus = null;
      }
      this.currentPage = 1;
      this.refreshAttendeeInfo();
    },
    displayResults() {
      this.currentPage = 1;
      this.refreshAttendeeInfo();
    },
    currentPage() {
      this.refreshAttendeeInfo();
    },
    queryString() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.currentPage = 1;
          this.refreshAttendeeInfo();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    // totalResults(){
    //     this.currentPage = 1;
    //     //this.fetchResults();
    // },
    // attendees(){
    //     this.totalResults = this.attendees.length;
    // },
    options: {
      handler(newVal) {
        // console.log(this.options);
        // debugger;
        this.order_by =
          newVal && newVal.sortBy && newVal.sortBy[0]
            ? newVal.sortBy[0]
            : "email";
        this.order_dir =
          newVal && newVal.sortDesc && newVal.sortDesc[0] ? "DESC" : "ASC";
        this.refreshAttendeeInfo();
      },
      deep: true,
    },
    event() {
      if (this.event) {
        this.refreshAttendeeInfo();
      }
    },
  },

  mounted() {

    this.refreshAttendeeInfo();

    // if (this.events == null) {
    //   this.getEvents();
    // }

  },

};
