<template>
  <div id="event-questions">
    <!-- {{event.attendees}} -->
    <!-- <h1>Registrations</h1> -->
    <div class="cta-container">
      <div class="info-container">
        <h1>Messages</h1>
        <p>
          {{ totalMessages }} Total Messages - Refreshing in: {{ refreshTimer }}
        </p>
      </div>

      <div class="cta" >
        <v-btn class="exportbtn" color="#1976d2" large @click="exportChat()"
          >Export Chat</v-btn
        >
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-group stretch">
        <div class="filter stretch">
          <v-text-field
            v-model="queryString"
            color="#CC4786"
            height="40"
            outlined
            block
            prepend-inner-icon="search"
            placeholder="Enter search string"
          ></v-text-field>
        </div>
      </div>
    </div>
    <br />
    <!-- <div class="no-data" v-if="attendees.length === 0">
            <h1>No results found</h1>
        </div> -->
    <!-- {{order_by}} - {{order_dir}} -->
    <div class="results-table">
      <!-- <p>Total Results: {{totalResults}}</p> -->
      <div class="results">
        <template>
          <v-data-table
            :headers="headers"
            :items="eventMessages"
            :options.sync="options"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
            class="elevation-1"
          >
            <template v-slot:item._id="{ item }">
              <!-- <v-icon @click.stop="updateFavoriteStatus(item)" class="question-status" :class="{highlighted: item.favorite}">mdi-star</v-icon> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="deleteChatMessage(item)"
                    class="question-delete"
                    >delete</v-icon
                  >
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>

            <template v-slot:item.user="{ item }">
              {{ item.user }}
            </template>

            <!-- <template v-slot:item.status="{ item }">
                        <v-checkbox
                            color="#00AC41"
                            :input-value="item.status === 'answered'"
                            @click.stop="updateStatus(item)"
                            
                        ></v-checkbox>
                    </template> -->

            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | formatDateTime }}
            </template>
          </v-data-table>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import EventModel from "../../lib/EventModel";
import axios from "axios";
// import SandiaEventModel from '../../lib/SandiaEventModel';
import ChatModel from "../../lib/ChatModel";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";
//import XLSX from "xlsx";

export default {
  props: ["event", "eventMongo"],
  data() {
    return {
      CONSTANTS,
      queryString: "",
      questionStatus: "",
      favoriteStatus: "",
      statusFilter: ["all", "pending", "answered"],
      favoriteFilter: ["all", "highlighted"],
      awaitingSearch: false,
      options: {},
      headers: [
        { text: "Created At", value: "createdAt", sortable: true },
        { text: "User", value: "user", sortable: true },
        { text: "Message", value: "message", sortable: true },
        { text: "Action", value: "_id", sortable: true, align: "center" },
        // { text: 'Status', value: 'status', sortable: true, align: 'center' },
      ],
      eventQuestions: [],
      eventMessages: [],
      loading: false,
      refreshTimer: 15,
      interval: null,
      socket: null, //io(CONSTANTS.SOCKET_SERVER_URL,{transports: ['websocket', 'polling']}),
      // timer:null
    };
  },

  computed: {
    totalQuestions() {
      return this.eventQuestions.length;
    },
    totalMessages() {
      return this.eventMessages.length;
    },
  },
  methods: {
    async loadQuestionData() {
      if (!this.event || !this.event._id) {
        return;
      }
      try {
        this.loading = true;
        let filter = {};
        if (this.queryString.trim().length > 0) {
          filter.query = this.queryString;
        }

        if (
          this.questionStatus.trim().length > 0 &&
          this.questionStatus !== "all"
        ) {
          filter.status = this.questionStatus;
        }

        if (this.favoriteStatus === "highlighted") {
          filter.highlighted = true;
        }

        this.eventQuestions = await EventModel.getEventQuestions(
          this.event,
          filter
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async updateStatus(item) {
      //alert(JSON.stringify(item));
      let newStatus = item.status === "answered" ? "pending" : "answered";
      item.status = newStatus;
      await EventModel.updateQuestionStatus(item);

      // alert('emitting');
      if (!this.loading) {
        console.log(
          this.socket.emit("message:update", {
            _id: item._id,
            event: this.event._id,
            status: newStatus,
          })
        );
      }
    },
    async updateFavoriteStatus(item) {
      //alert(JSON.stringify(item));
      let newStatus = item.favorite ? false : true;
      item.favorite = newStatus;

      await EventModel.updateQuestionFavoriteStatus(item);

      if (!this.loading) {
        console.log(
          this.socket.emit("message:update", {
            _id: item._id,
            event: this.event._id,
            favorite: newStatus,
          })
        );
      }
    },

    registerEvents() {
      if (!this.socket) {
        console.warn("NO SOCKET YET!!!");
        return;
      }

      this.socket.on("connect", () => {
        console.log("connected");
        console.log(this.eventMongo);
      });

      this.socket.emit("chat:signin", {
        type: "signin",
        event: `worldchef-admin-${this.eventMongo._id}`,
        id: 1,
      });

      this.socket.on("chat:room", () => {
        console.log("Connected to ");
        console.log(`worldchef-admin-${this.eventMongo._id}`);
      });
      // Reconnect
      this.socket.on("reconnect", () => {
        this.socket.emit("chat:signin", {
          type: "reconnect",
          event: `worldchef-admin-${this.eventMongo._id}`,
          id: 1,
        });
      });

      this.socket.on("message:received", async () => {
        this.loading = true;
        await this.loadQuestionData();
        this.loading = false;
      });
      this.socket.on("message:updated", async (data) => {
        try {
          this.loading = true;

          if (!Array.isArray(this.eventQuestions)) {
            this.eventQuestions = [];
          }
          let questions = [...this.eventQuestions];
          let position = questions.findIndex((el) => el._id === data._id);
          if (position > -1) {
            questions[position] = data;
            this.$set(questions, position, data);
          }
          await this.loadQuestionData();
        } finally {
          this.loading = false;
        }
      });
    },

    async loadMessages() {
      // eventMessages
      if (!this.event || !this.event._id) {
        return;
      }
      try {
        this.loading = true;
        clearInterval(this.tick);
        this.refreshTimer = 2;
        let filter = {};
        if (this.queryString.trim().length > 0) {
          filter.query = this.queryString.trim();
        }
        this.eventMessages = await ChatModel.getEventChatMessages(
          this.event._id,
          filter
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        if (!this.interval) this.interval = setInterval(this.tick, 1000);
      }
    },

    async deleteChatMessage(message) {
      if (!message) {
        return;
      }
      try {
        var r = confirm(
          "This action can not be undone, are you sure you wan't to delete this message?"
        );
        if (r == true) {
          this.loading = true;
          await ChatModel.deleteEventChatMessages(message._id);
          this.socket.emit("chat:remove", {
            _id: message._id,
            event: this.eventMongo._id,
          });
          await this.loadMessages();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    tick() {
      let remainingTime = this.refreshTimer - 1;
      if (remainingTime < 1) {
        remainingTime = 2;
        this.loadMessages();
      }
      // else{
      //     remainingTime -= 1;
      // }
      this.refreshTimer = remainingTime;
      // console.log('tick', this.refreshTimer, remainingTime);
    },

    exportChat() {

      let url_id = this.$route.params.event_id;

      axios
        .request({
          url: this.CONSTANTS.API_MONGO_BASE_URL + 'api/reports/chats?event=' + url_id,
          method: "GET",
          headers: { "x-token-auth": localStorage.getItem("token") },
          responseType: "blob",
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "Chats.xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  watch: {
    queryString() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.currentPage = 1;
          //this.loadQuestionData();
          this.loadMessages();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    questionStatus(newVal) {
      if (newVal === "all") {
        this.questionStatus = "";
      }
      this.loadQuestionData();
    },
    favoriteStatus(newVal) {
      if (newVal === "all") {
        this.favoriteStatus = "";
      }
      this.loadQuestionData();
    },

    options: {
      handler(newVal) {
        // console.log(this.options);
        // debugger;
        this.order_by =
          newVal && newVal.sortBy && newVal.sortBy[0]
            ? newVal.sortBy[0]
            : "email";
        this.order_dir =
          newVal && newVal.sortDesc && newVal.sortDesc[0] ? "DESC" : "ASC";
        //this.loadQuestionData();
        this.loadMessages();
      },
      deep: true,
    },
    event() {
      if (this.event) {
        this.loadMessages();
      }
    },
    eventMongo(newVal) {
      if (newVal && newVal._id) {
        this.socket = io(CONSTANTS.SOCKET_SERVER_URL, {
          secure: true,
          transports: ["websocket", "polling"],
        });
        this.registerEvents();
      }
    },
  },
  async mounted() {
    this.interval = setInterval(this.tick, 1000);
    await this.loadMessages();
    if (this.eventMongo && this.eventMongo._id) {
      this.socket = io(CONSTANTS.SOCKET_SERVER_URL, {
        secure: true,
        transports: ["websocket", "polling"],
      });
      this.registerEvents();
    } else {
      // await this.fetchEventInfo();
    }
    // this.registerEvents();
    // this.timer = setTimeout(()=>{ this.show = false; console.log('Timer');}, 5000);
    //this.totalResults = this.attendees.length;
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
<style>
.cta {
  margin-top: 5em;
}
</style>
