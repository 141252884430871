import "animate.css";
import Loading from "../components/Loading/Loading.vue";
import axios from "axios";
import CONSTANTS from "../lib/Constants.js";
//import EventModel from "../lib/EventModel.js";
import moment from 'moment';
//import { format, parseISO } from 'date-fns'

import Swal from "sweetalert2";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      props: [],
      showLoader: false,
      CONSTANTS,
      dialog: false,
      mode: "Seminar Create",
      id: "",
      ruleForm: {
        name: "",
        type: "seminar",
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Events", value: "events", sortable: false},
        { text: "Star Date", value:"start", sortable: false},
        { text: "End Date", value:"end", sortable: false},
        { text: "Actions", value: "actions", sortable: false },
      ],
      type: "",
      events: [],
      eventsChildren: 0,
      loading: false,
      totalEvents: [],
      startEvents: [],
      endEvents: [],
    };
  },
  computed: {
    availablePages() {
      return Math.ceil(this.totalResults / this.displayResults);
    },
    availablePagesList() {
      let pages = [];
      for (let i = 0; i < this.availablePages; i++) {
        pages.push(i + 1);
      }
      return pages;
    },
  },
  methods: {
    getColor (count) {
      if (count) return 'primary'
    },

    getLoad(data) {
      this.events = [];
      this.eventsChildren = 0;
      if (data == 2024) { this.fetchEvents('2024') }
      if (data == 2023) { this.fetchEvents('2023') }
      if (data == 2022) { this.fetchEvents('2022') }
    },

    fetchEvents(year) {
      return new Promise((resolve, reject) => {
        this.showLoader = true;
        let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events?perPage=1000";

        let config = {
          headers: {
            "x-token-auth": localStorage.getItem("token"),
          },
        };

        axios
          .get(url, config)
          .then(async (result) => {
            if (result.data) {

              this.eventsChildren = result.data.data.items.filter((el) => {
                //console.log(el, 'el')
                if (el.active && el.parent && el.type.indexOf('seminar') >= 0 && moment(el.startDate).format('yyyy') === year) {
                  return el;
                }
              });
              // console.log(this.eventsChildren, 'Hijos')

              this.events = result.data.data.items.filter((el) => {
                // console.log(el, 'el')
                if (el.active && el.type.indexOf('seminar') >= 0 && !el.parent && moment(el.startDate).format('yyyy') === year) {
                  return el;
                }

              });
              // console.log( 'Padre',this.events)

              for(let i=0; i < this.events.length; i++) {
                let id = this.events[i].id;
                let count = this.eventsChildren.filter(item => item.parent === id).length;
                let start = this.events[i].startDate;
                let startFormat = moment(start).format('yyyy-MM-DD').toString();
                let end = this.events[i].endDate;
                let endFormat = moment(end).format('yyyy-MM-DD').toString();

                // console.log(startFormat, 'Start')
                this.totalEvents[id]=count;
                this.startEvents[id]=startFormat;
                this.endEvents[id]=endFormat;

                //this.totalEvents.push({'id': id, 'count':count });
                //console.log(this.totalEvents,'Total Eventos')
              }
              //console.log('Eventos', this.events[0])
              resolve();
            }
            reject();
          })
          .catch((error) => {
            // error.response.data.error.detail || "Error: Invalid credentials";
            alert("An error has ocurred");
            console.error(error);
            reject(error);
          })
          .finally(()=>{
             this.showLoader = false;
          });
      });
    },

    onDialogCreate() {
      this.mode = "Seminar Create";
      this.dialog = true;
    },

    submitForm(formName) {
      this.loading = true;
      return this.saveForm(formName);
    },

    resetForm() {
      this.ruleForm.name = "";
      this.dialog = false;
    },

    async saveForm() {
      new Swal({
        customClass: {
          title: "title-class-create",
        },
        title: "Please Wait",
        allowOutsideClick: false,
      });
      Swal.showLoading();

      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events";
      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      axios.post(url, this.ruleForm, config).then((r) => {
        // console.log(r);
        if (r) {
          this.dialog = false;
          this.resetForm();
          this.loading = false;
          this.fetchEvents();
          this.showLoader = false;
          return true;
        } else {
          return false;
        }
      });

      Swal.fire({
        customClass: {
          title: "title-class-create",
          htmlContainer: "text-class",
          confirmButton: "confirmButton-class-create",
        },
        title: "Saved.",
        text: "Successfully registered seminar",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1976d2",
        icon: "success",
      });
    },
    async editEvent(eventid) {
      //console.log(eventid);
      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events";
      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      //console.log(url);

      axios.get(`${url}/${eventid}`, config).then((r) => {
        //console.log(r, 'load event');
        this.mode = "Edit Seminar";
        this.dialog = true;
        this.ruleForm.name = r.data.data.name;
        //console.log(r.data.data.name);
      });
      this.id = `${eventid}`;
    },

    async submitUpdateForm(formName) {
      const id = this.id;
      return this.saveUpdateForm(formName, id);
    },

    async saveUpdateForm(event, id) {
      new Swal({
        customClass: {
          title: "title-class",
        },
        title: "Please Wait",
        allowOutsideClick: false,
      });
      Swal.showLoading();

      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/events";
      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      axios.put(`${url}/${id}`, this.ruleForm, config).then((r) => {
        // console.log(r);
        if (r) {
          this.resetForm();
          this.fetchEvents();
          this.showLoader = false;
        }
      });
      this.loading = false;

      Swal.fire({
        customClass: {
          title: "title-class-update",
          htmlContainer: "text-class",
          confirmButton: "confirmButton-class-update",
        },
        title: "Updated.",
        text: "Event successfully updated",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1976d2",
        icon: "success",
      });
    },
    showEvents(event) {
      //console.log(event);
      this.$router.push({ path: `/admin/seminars/events-seminars/${event.id}` });
      //this.$router.push({ path: `/admin/seminars/events-seminars/15/PruebaRaymer` });
    },
  },

  async mounted() {
    try {
      this.showLoader = true;
      await this.getLoad(2024);
    } catch (error) {
      console.error(error);
      debugger;
    } finally {
      this.showLoader = false;
    }
  },
};
