<template>
    <div id="lancome-admin-event-registrants">
        <Loading :show="showLoader"/>
        <NavBar />
        <EventInfoBar :event="event" :selected="0"/>
        <div class="info-container">
            <router-view
                v-on:show_loader="showLoader = true"
                v-on:hide_loader="showLoader = false"
                :event="event"
                :eventMongo="event"
                :key="$route.path"
            />
        </div>
    </div>
</template>
<script>
import NavBar from '../components/NavBar/NavBar.vue';
import EventInfoBar from '../components/EventInfoBar/EventInfoBar.vue';
import Loading from '../components/Loading/Loading.vue';
import axios from 'axios';
import CONSTANTS from '../lib/Constants.js'
import EventModel from '../lib/EventModel.js';

export default {
    components:{
        NavBar,
        EventInfoBar,
        Loading
    },
    props:['event_id'],
    data(){
        return {
            showLoader:false,
            CONSTANTS,
            EventModel,
            event:{}
        }
    },
    methods:{
        fetchEventInfo(){
            this.showLoader = true;
            let url = this.CONSTANTS.API_MONGO_BASE_URL + 'api/events/' + this.event_id;


            let config = {
                headers : {
                    "x-token-auth" : localStorage.getItem('token')
                }
            }
            axios.get(url,config).then(async (result)=>{
                // console.log(result);
                if(result.data){
                    this.event = result.data.data;
                    let tempEvent = result.data.data;

                    if(!tempEvent.sandia){
                        tempEvent.sandia = tempEvent._id;
                    }
                    this.event = tempEvent;
                    let attendees = await EventModel.getAttendeeCount(tempEvent.sandia);

                    this.$set(this.event,'attendees', attendees);
                    // tempEvent.attendees = attendees;

                    // this.event = tempEvent;

                }


            })
            .catch(error=>{
                // error.response.data.error.detail || "Error: Invalid credentials";
                alert('An error has ocurred');
                console.log(error);

            })
            .finally(()=>{
                this.showLoader = false;
            });
        },
        navigateToEvent(event){
            //alert(JSON.stringify(event));
            this.$router.push({name:'Admin', params:{event:event}});
        }
    },
    mounted(){
        this.fetchEventInfo();

    }
}
</script>
<style lang="scss">
#lancome-admin-event-registrants{
    margin-top: 89px;
    .info-container{
        width:90%;
        max-width: 1200px;
        margin: 0 auto;
    }

}

</style>