// import background from '../assets/img/lancome_background.png';
import logoysl from "../assets/img/ysl.png";
import logoga from "../assets/img/armani.png";
import TextInput from "../components/TextInput/TextInput.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import CONSTANTS from "../lib/Constants.js";
import Auth from "../lib/Auth.js";

import Swal from "sweetalert2";

export default {
  components: { TextInput },
  data() {
    return {
      logoysl,
      logoga,
      CONSTANTS,
      email: '',
      password: '',
      verification: "",
      signingIn: false,
      showLogin: true,
      showVerify: false,
      loginError: null,
      id: '',
    };
  },
  validations: {
    email: {
      required,
      minLength: minLength(4),
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  computed: {
    emailError() {
      return this.getErrorMessage(this.$v.email);
    },
    passwordError() {
      return this.getErrorMessage(this.$v.password);
    },
  },
  methods: {
    getErrorMessage(model) {
      if (model == null || !model.$dirty) {
        return "";
      }

      let message = "";
      // debugger;
      if (typeof model.required !== "undefined" && !model.required) {
        message = "This field is required";
      }
      if (typeof model.minLength !== "undefined" && !model.minLength) {
        message =
          "You must enter at least " +
          model.$params.minLength.min +
          " characters";
      }
      if (
        typeof model.sameAsPassword !== "undefined" &&
        !model.sameAsPassword
      ) {
        message = "Entered value doesn't match";
      }
      if (typeof model.email !== "undefined" && !model.email) {
        message = "Value must be valid email";
      }

      return message;
    },
    isFormValid() {
      let isValid = true;

      if (this.$v.email.$invalid) {
        isValid = false;
      }
      if (this.$v.password.$invalid) {
        isValid = false;
      }

      return isValid;
    },

    /*
    async handleSignIn() {
      this.loginError = null;
      let isFormValid = this.isFormValid();
      if (!isFormValid) {
        return;
      }

      this.signingIn = true;
      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/auth/login";
      let payload = {
        email: this.email,
        password: this.password,
      };
      let headers = null;
      axios
        .post(url, payload, headers)
        .then(async (result) => {
          let isAdmin = false;
          console.log(result.data.data.user);

          if (result.data.data.user.profile == "admin") isAdmin = true;

          if (result.data.success) {
            let credentials = {
              token: result.data.data.token,
              firstName: result.data.data.user.firstName,
              lastName: result.data.data.user.lastName,
              profile: result.data.data.user.profile,
              id: result.data.data.user._id,
              email: result.data.data.user.email,
              isAdmin: isAdmin,
            };
            
            await Auth.storeCredentials(credentials);
            this.$router.push({ name: "inductions" });
          }
        })
        .catch((error) => {
          console.log('credential invalida pollo');
          this.loginError =
            error.response.data.error.detail || "Error: Invalid credentials";
        })
        .finally(() => {
          this.signingIn = false;
        });
    },

*/

    async handleSignIn() {
      this.loginError = null;
      let isFormValid = this.isFormValid();
      if (!isFormValid) {
        return;
      }

      this.signingIn = true;
      let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/auth/login";
      let payload = {
        email: this.email,
        password: this.password,
      };

      axios
        .post(url, payload)
        .then(async (result) => {
          console.log(result);
          if (result.request.status == 200) {
            this.id = result.data.data.id;
            this.showLogin = false;
            this.showVerify = true;
            this.signingIn = true;
          }
        })
        .catch((error) => {
          console.log("credential invalida");
          this.loginError =
            error.response.data.error.detail || "Error: Invalid credentials";
        })
        .finally(() => {
          this.signingIn = false;
        });
    },

    async handleSignInVerify() {
      if (this.verification === "") {
        await Swal.fire({
          customClass: {
            title: "title-class-create",
            htmlContainer: "text-class",
            confirmButton: "confirmButton-class-create",
          },

          title: "Error",
          text: "This field is required. Enter your verification code",
          confirmButtonText: "Ok",
          confirmButtonColor: "#f21d1d",
          icon: "error",
        });
      } else {
        this.signingIn = true;
        let url = this.CONSTANTS.API_MONGO_BASE_URL + "api/auth/validate";
        let payload = {
          email: this.email,
          password: this.password,
          code: this.verification,
          key: this.id
        };

        //console.log(payload);
        let headers = null;
        axios
          .post(url, payload, headers)
          .then(async (result) => {
            let isAdmin = false;
            console.log(result.data.data.user);

            if (result.data.data.user.profile == "admin") isAdmin = true;

            if (result.data.success) {
              let credentials = {
                token: result.data.data.token,
                firstName: result.data.data.user.firstName,
                lastName: result.data.data.user.lastName,
                profile: result.data.data.user.profile,
                id: result.data.data.user._id,
                email: result.data.data.user.email,
                isAdmin: isAdmin,
              };

              await Auth.storeCredentials(credentials);
              this.$router.push({ name: "inductions" });
            }
          })
          .catch(async (error) => {

            const  result = await Swal.fire({
              customClass: {
                title: "title-class-create",
                htmlContainer: "text-class",
                confirmButton: "confirmButton-class-create",
              },

              title: "Warning",
              text: "Invalid verification code",
              confirmButtonText: "Request a new code",
              confirmButtonColor: "#1976d2",
              icon: "warning",
            });

            if ( result.isConfirmed) {
              this.handleSignIn();
            }
            this.verification ='';

            console.log("credential invalida");
            this.loginError =
              error.response.data.error.detail || "Error: Invalid credentials";
            
          })
          .finally(() => {
            this.signingIn = false;
          });
      }
    },
  },
};
